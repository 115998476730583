import React, { useState } from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    Accordion,
    AccordionHeader,
    AccordionBody,
    IconButton,
    Drawer,
} from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    PowerIcon,
} from "@heroicons/react/24/solid";
import {
    ChevronRightIcon,
    ChevronDownIcon,
    XMarkIcon,
    Bars3Icon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@resources/routes-constants";
import { useDispatch } from "react-redux";
import { logout } from "src/features/auth/authSlice";
import VennLogo from '@assets/images/UI/Venn.png';

export function MainSideBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value: number) => {
        setOpen(open === value ? 0 : value);
    };

    const websiteContentList = [
        {
            title: 'Event',
            route: ROUTES.EVENT_ROUTE
        },
        {
            title: 'Promo',
            route: ROUTES.PROMO_ROUTE
        },
        {
            title: 'Headline',
            route: ROUTES.HEADLINE_ROUTE
        },
        {
            title: 'Drink',
            route: ROUTES.DRINK_ROUTE
        },
    ]

    const handleLogout = () => {
        dispatch(logout());
    };
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    return (
        <>
            <IconButton variant="text" size="lg" onClick={openDrawer}>
                {isDrawerOpen ? (
                    <XMarkIcon className="h-8 w-8 stroke-2" />
                ) : (
                    <Bars3Icon className="h-8 w-8 stroke-2" />
                )}
            </IconButton>
            <Drawer open={isDrawerOpen} onClose={closeDrawer}>
                <Card
                    className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5"
                >
                    <div className="mb-2 flex items-center gap-4 p-4 cursor-pointer" onClick={() => { setSidebarOpen(!sidebarOpen) }}>
                        <img src={VennLogo} alt="brand" className="w-14" />
                        <Typography
                            variant="h5"
                            color="blue-gray"
                        >
                            Dashboard
                        </Typography>
                    </div>
                    <List>
                        <Accordion
                            open={open === 1}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 1}>
                                <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                                    <ListItemPrefix>
                                        <PresentationChartBarIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography
                                        color="blue-gray"
                                        className="mr-auto font-normal"
                                    >
                                        Website Content
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                <List className="p-0">
                                    {
                                        websiteContentList.map((value, index) => {
                                            return <ListItem key={index} onClick={() => {
                                                navigate(value.route);
                                                closeDrawer();
                                            }}>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                                </ListItemPrefix>
                                                <Typography
                                                    color="blue-gray"
                                                    className="mr-auto font-normal"
                                                >
                                                    {value.title}
                                                </Typography>
                                            </ListItem>
                                        })
                                    }
                                </List>
                            </AccordionBody>
                        </Accordion>
                        <ListItem onClick={handleLogout}>
                            <ListItemPrefix>
                                <PowerIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography
                                color="blue-gray"
                                className="mr-auto font-normal"
                            >
                                Log Out
                            </Typography>

                        </ListItem>
                    </List>
                </Card >
            </Drawer>
        </>
    );
}