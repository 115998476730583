import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@resources/routes-constants';
import { Button, Card, Checkbox, Input, Typography } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { isAsyncThunkAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store/reducers/store';
import { userLogin } from 'src/features/auth/authAction';
import ErrorComponent from '@components/Error';
// Login.jsx
export const LoginPage = () => {
    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const { loading, error, userInfo } = useSelector((state: RootState) => state.user)
    const accessToken = userInfo?.accessToken;

    const handleLogin = async (data: any) => {
        const { email, password } = data;
        if (!email || !password) return;
        try {
            dispatch(userLogin({ email, password }))
        } catch (e) {
            // handle your error
        }

    };

    // redirect authenticated user to profile screen
    useEffect(() => {
        if (accessToken) {
            navigate(ROUTES.EVENT_ROUTE)
        }
    }, [navigate, accessToken])

    return <Card color="transparent" shadow={false} className='mx-auto max-w-screen-sm py-4 px-8 mt-10 lg:mt-16 bg-white '>
        {error && <ErrorComponent>{error}</ErrorComponent>}
        <Typography variant="h4" color="blue-gray">
            Sign In
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
            Welcome back! Please sign in to continue.
        </Typography>
        <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={handleSubmit(handleLogin)} >
            <div className="mb-1 flex flex-col gap-6">
                <Input
                    {...register('email')}
                    type='email'
                    size="lg"
                    label='Email'
                    placeholder="user@venn.com"
                    autoComplete='email'
                    required
                />
                <Input
                    {...register('password')}
                    type='password'
                    size="lg"
                    label='Password'
                    placeholder="********"
                    autoComplete='password'
                    required
                />
                <Button className="mt-6 flex justify-center" type='submit' fullWidth loading={loading} >
                    sign in
                </Button>
            </div>
        </form>
    </Card>
};